:root {
    --primary: #5b2a86;
    --secondary: #7785ac;
    --tertiary: #9ac6c5;
    --dark: #360568;
    --light: #a5e6ba;
  }

.contact-container {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .contact-box {
    padding: 20px;
    background-color: var(--secondary);
    margin-top: 60px;

  }
  
  .contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 30px 0px 10px 30px;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
  
  .info-item a {
    text-decoration: none;
    padding: 5px 10px;
    color: var(--dark);
    margin-right: 10px;
    background-image: linear-gradient(270deg, var(--dark) 0%, var(--dark) 100%);
    background-repeat: no-repeat;
    background-size: 95% 0.2em;
    background-position: bottom;
    transition: all 0.25s ease-in;
    font-size: 1.2rem;
  }

  .info-item a:hover {
    background-size: 100% 100%;
    color: var(--primary);
    font-weight: bold; 
    background-image: linear-gradient(270deg, var(--light) 0%, var(--light) 100%);
  }

  .info-item span {
    width: 80px;
    font-weight: bold;
  }
  
  .info-icon {
    color: var(--light);
    background-image: linear-gradient(270deg, var(--light) 0%, var(--light) 100%);
    background-repeat: no-repeat;
    background-size: 0% 0.2em;
    background-position: bottom;
    transition: all 0.25s ease-in;
  }

  .info-icon:hover {
    background-size: 100% 100%;
    color: var(--primary);
  }


  /* .info-item span::after {
    content: ":";
    margin-right: 5px;
  } */
  