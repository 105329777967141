:root {
  --primary: #5b2a86;
  --secondary: #7785ac;
  --tertiary: #9ac6c5;
  --dark: #360568;
  --light: #a5e6ba;
}

.bio-container {
  overflow: hidden;
  flex-direction: column;
  padding: 20px;
}

.bio-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.center-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

img {
    width: 200px;
    height: auto;
    margin-right: 20px;
  /* float: left;
  margin-right: 10px;
  margin-left: 220px;
  margin-top: 20px;
  border: 5px solid var(--dark); */
  /* display: flex; */
}



.bio-text-box {
  /* width: 600px;
  height: 200px;
  margin-top: 70px;
  margin-right: 30px; */
  border: 5px solid var(--dark);
  color: var(--dark);
  background-color: var(--light);
  flex-basis: calc(50%-1em);
  display: flex;
  /* float: right; */
  /* justify-content: center;
  align-items: center;
  text-align: center; */
  overflow: hidden;
  padding: 10px;
  text-decoration: none;
  background-blend-mode: soft-light;
  background-size: 150%;
  transition: all 0.5s;
  font-size: 10pt;
  max-width: 600px;
  word-wrap: break-word;
}

@media screen and (max-width: 768px) {
  .center-box {
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-picture {
    margin-right: 0;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

