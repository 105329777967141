:root {
    --primary: #5B2A86;
    --secondary: #7785AC;
    --tertiary: #9AC6C5;
    --dark: #360568;
    --light: #A5E6BA;
}

header {
    padding: 15px 6% 15px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    background-color: var(--primary);
    box-shadow: 0 5px 35px rgba(150, 200, 205, 0.5)
    }

header h1 {
    color: var(--primary);
    background-color: var(--light);
    padding: 5px 1% 5px 6%;
    margin: 0;
    font-size: 4vw;
    line-height: 1;
}

nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    }

    nav a {
        text-decoration: none;
        padding: 5px 10px;
        color: var(--light);
        margin-right: 10px;
        background-image: linear-gradient(270deg, var(--light) 0%, var(--light) 100%);
        background-repeat: no-repeat;
        background-size: 100% 0.2em;
        background-position: bottom;
        transition: all 0.25s ease-in;
        font-size: 1.4rem;
    }

    nav a:hover {
        background-size: 100% 100%;
        color: var(--primary);
    }

    @media screen and (max-width: 768px) {
      nav a {
        font-size: 0.8rem;
        background-size: 100% 0.1em;
      }
    }

    /* @media screen and (max-width: 768px) {
        nav {
          flex-direction: column;
          align-items: center;
          display: none;
        }
        nav.show {
          display: flex;
        }
        nav a {
          display: block;
          margin: 10px 0;
        }
      }
      
      .hamburger {
        display: none;
        cursor: pointer;
        padding: 5px;
      }
      
      .hamburger span {
        display: block;
        width: 30px;
        height: 3px;
        background-color: var(--light);
        margin-bottom: 5px;
      } */
      
      /* @media screen and (max-width: 768px) {
        .hamburger {
          display: block;
        }
      
        .hamburger.hide {
          display: none;
        }
      }
       */