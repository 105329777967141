:root {
    --primary: #5B2A86;
    --secondary: #7785AC;
    --tertiary: #9AC6C5;
    --dark: #360568;
    --light: #A5E6BA;
}

footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    background-color: var(--primary);
    box-shadow: 0 5px 35px rgba(150, 200, 205, 0.5)
}

footer h4 {
    color: var(--primary);
    background-color: var(--light);
    padding: 5px ;
    margin: 0;
    line-height: 1;
}

